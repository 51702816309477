<template>
	<div id="sales-list-container">
		<table class="sales-list" v-if="unifiedSalesReportList.length > 0">
			<tr class="header-row sticky">
				<th colspan="2" class="gray">Casino</th>
				<th colspan="2" class="green">Cashier</th>
				<th colspan="2" class="med-orange">MRU</th>
				<th colspan="2" class="soft-blue">WebPay</th>
				<th class="darker-lavender">Total</th>
			</tr>
			<tr class="header-row sticky">
				<th class="gray">Id</th>
				<th class="gray">Name</th>
				<th class="green">Cash-In</th>
				<th class="green">Cash-Out</th>
				<th class="med-orange">Cash-In</th>
				<th class="med-orange">Cash-Out</th>
				<th class="soft-blue">Cash-In</th>
				<th class="soft-blue">Cash-Out</th>
				<th class="darker-lavender">Hold</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in unifiedSalesReportList" :key="index" :value="item.id">
				<td class="center-align">{{ item.casinoId }}</td>
				<td class="left-align">{{ item.casinoName }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.cashierCashInValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.cashierCashOutValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.mruCashInValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.mruCashOutValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.webCashInValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(item.webCashOutValueCOC, systemWholeOrFull) }}</td>
				<td class="right-align" :class="item?.totalHold.sign ? '' : 'negative'">
					{{ systemCurrencyTool.formatCurrency(item?.totalHold.value, systemWholeOrFull) }}
				</td>
			</tr>
			<tr class="total-row">
				<td class="center-align" colspan="2">Total</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.cashInTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.cashOutTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.mruInTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.mruOutTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.webInTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.webOutTotal, systemWholeOrFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(totals.sumTotalHolds, systemWholeOrFull) }}</td>
			</tr>
		</table>
		<div v-else>
			<h2 class="no-records">No Unified Sales Reports</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "UnifiedSalesReportTable",
	inheritAttrs: false,
	props: {
		selectedCasino: Object,
		unifiedSalesReportList: Array,
		totals: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemFull: this.systemCurrencyTool.displayType.full,
			systemWholeOrFull: this.systemCurrencyTool.displayType.wholeOrFull,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#sales-list-container {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
}

.sales-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.sales-list th,
.sales-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.sales-list tr.data-row {
	color: #000;
}

.sales-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.header-row th {
	border-color: #fff;
}

.sales-list tr:nth-child(2) th {
	border-color: #fff #fff #000;
}

.sales-list tr.total-row {
	font-weight: bold;
	background-color: #000;
	color: #fff;
}

.gray {
	background-color: #525252;
}

.green {
	background-color: #077607;
}

.med-orange {
	background-color: #bd7222;
}

.soft-blue {
	background-color: #5579a5;
}

.darker-lavender {
	background-color: #2c4feb;
}

.no-records {
	color: #000;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.center-align {
	text-align: center;
}

.negative {
	background-color: #ffffbd;
	color: #f00;
	font-weight: bold;
}
</style>
